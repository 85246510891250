import { digest } from '@/utils/hashing';
import { mapGetters } from 'vuex';
import { getAccountIdFromCookie } from '@/util';

export default {
  computed: {
    ...mapGetters(['hasAccountFeature', 'baseThemeKitIds']),
    templateUpdatedAt() {
      const updatedAt = this.template.updatedAt || '2000-12-12T00:00:00.000Z';
      return new Date(updatedAt);
    },

    needAccountIdForRender() {
      return this.isUniversal && this.themeKit;
    },
    isUserTheme() {
      if (!this.themeKit) return false;
      return !this.baseThemeKitIds.includes(this.themeKit?.id);
    },
    needColorParameter() {
      const onWizardRoute = this.$route?.path?.includes?.('wizard') ?? false;
      const isCustomOrInWizard = this.isUserTheme || onWizardRoute;
      return this.isUniversal && isCustomOrInWizard;
    },
  },
  methods: {
    getColor() {
      if (this.themeKit) return this.color || this.themeKit?.colors?.mainColor;
      return this.color;
    },
    getSkeletonVersion() {
      const isEmbedded = this.isEmbedded || this.template?.type === 'embedded';
      return isEmbedded ? 'embedded-v2' : undefined;
    },
    async ssrQueryParams() {
      const variables = {
        ts: this.templateUpdatedAt.getTime(),
      };

      if (this.isUniversal) {
        variables.themeKit = this.themeKit;
      }

      const params = {
        v: await digest(variables),
        theme: this.isUserTheme ? this.themeKit?.id || undefined : undefined,
        skeleton: this.getSkeletonVersion(),
      };

      if (this.needColorParameter) {
        params.color = this.getColor();
      }

      if (this.needAccountIdForRender && this.isUserTheme) {
        params.account = getAccountIdFromCookie();
      }

      return {
        ...params,
      };
    },
  },
};
