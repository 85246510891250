import runtimeConfig from '@/config/runtime';
import ssrParamsMixin from '@/mixins/ssrParams';
import axios from 'axios';
import { debounce, get } from 'lodash-es';

const R_FHD = { width: 1920, height: 1080 };
const R_HDPLUS = { width: 1600, height: 900 };
const R_HD = { width: 1280, height: 720 };
const R_W1024 = { width: 1024, height: 576 };

const RESOLUTIONS = {
  '16/9': { large: R_W1024, medium: R_HDPLUS, normal: R_HD, small: R_FHD },
};

export default {
  props: {
    transparentOverlay: {
      type: Boolean,
      default: true,
    },
    allowSsr: {
      type: Boolean,
      default: false,
    },
    ready: {
      type: Boolean,
      default: true,
    },
    dimensions: {
      type: Object,
      default: () => ({
        width: null,
        height: null,
      }),
    },
    removeHidden: {
      type: Boolean,
      default: false,
    },
    clearOnChange: {
      type: Boolean,
      default: false,
    },
    themeKit: {
      type: Object,
      default: null,
    },
    endpoint: { type: String, default: null },
    payload: { type: Object, default: null },
  },
  mixins: [ssrParamsMixin],
  data: () => ({
    aspectRatio: '16/9',
    paletteRef: 'lazyFrame',
    loading: false,
    ssr: true,
    zIndex: '-999999',
    visible: false,
    shown: false,
    transformOrigin: null,
    contentProperties: {
      isSidebar: false,
      isNanobar: false,
      isInterstitial: false,
      isFullscreen: false,
      hasWheel: false,
      fitToScreen: false,
      isEmbedded: false,
    },
    cancelled: false,
    axiosAbortController: null,
    axiosCancelTokenSource: null,
  }),
  computed: {
    isOldVersionCampaign() {
      return this.campaign && this.campaign.version === 1;
    },
    sizing() {
      return this.isFullheightSidebar ? 'small' : 'normal';
    },
    baseWidth() {
      const resolution = RESOLUTIONS[this.aspectRatio];
      return resolution[this.sizing].width;
    },
    baseHeight() {
      const resolution = RESOLUTIONS[this.aspectRatio];
      return resolution[this.sizing].height;
    },
    frameWidth() {
      return `${this.baseWidth}px`;
    },
    frameHeight() {
      return `${this.baseHeight}px`;
    },
    frameScale() {
      const { width, height } = this.dimensions;
      return Math.min(width / this.baseWidth, height / this.baseHeight);
    },
    frameStyle() {
      return {
        display: this.visible ? undefined : 'none',
        'min-width': this.frameWidth,
        'min-height': this.frameHeight,
        'z-index': this.zIndex,
        transform: `scale(${this.frameScale})`,
        'transform-origin': this.transformOrigin,
        height: this.isEmbedded ? '100%' : '',
      };
    },
    ssrUrl() {
      const { VUE_APP_SSR_CDN_URL: ssrUrl } = runtimeConfig;
      if (ssrUrl) {
        let url = `${ssrUrl}/thumbnail/${this.template._id}`;
        if (this.preferredTemplateLanguage !== 'en' && this.$route.name !== 'template_manager') {
          url += `?language=${this.preferredTemplateLanguage}`;
        }

        return url;
      }
      return null;
    },
    brandKitSsrUrl() {
      const { VUE_APP_SSR_CDN_URL: ssrUrl } = runtimeConfig;
      if (ssrUrl) {
        let url = `${ssrUrl}/brand-kit-preview/${this.template._id}`;
        if (this.preferredTemplateLanguage !== 'en' && this.$route.name !== 'template_manager') {
          url += `?language=${this.preferredTemplateLanguage}`;
        }
        return url;
      }
      return null;
    },
    customEndpointUrl() {
      const { VUE_APP_SSR_CDN_URL: ssrUrl } = runtimeConfig;
      if (ssrUrl) {
        return `${ssrUrl}/${this.endpoint}/${this.template._id}`;
      }
      return null;
    },
    hasWheel() {
      return this.contentProperties.hasWheel;
    },
    isSidebar() {
      return this.contentProperties.isSidebar;
    },
    isNanobar() {
      return this.contentProperties.isNanobar;
    },
    isFullheightSidebar() {
      return this.contentProperties.fitToScreen;
    },
    isInterstitial() {
      return this.contentProperties.isInterstitial;
    },
    isFullscreen() {
      return this.contentProperties.isFullscreen;
    },
    isEmbedded() {
      return this.contentProperties.isEmbedded;
    },
    isUniversal() {
      return get(this.template, 'universal', false);
    },
    isPopup() {
      return !this.isNanobar && !this.isSidebar;
    },
  },

  watch: {
    ready(n) {
      if (this.shown && this.visible && !this.cancelled && n) {
        this.setFrameContent().then(() => {
          this.$nextTick(() => {
            this.$nextTick(() => {
              this.$emit('contentLoaded');
            });
          });
        });
      }
    },
    payload: {
      deep: true,
      handler: debounce(function (n, o) {
        if (!this.isUniversal || JSON.stringify(n) === JSON.stringify(o)) return;
        this.setFrameContent().then(() => {
          this.$emit('contentLoaded');
        });
      }, 300),
    },
  },

  created() {
    if (!this.allowSsr || this.isOldVersionCampaign) {
      this.ssr = false;
    }
  },
  mounted() {
    if (!this.ssr) return;
    this.$emit('inited', this);
    if (window.innerWidth <= 575) {
      this.ssr = false;
      return;
    }
    if (!this.ssrUrl) {
      this.ssr = false;
      return;
    }
    this.$el.__comp__ = this;
    this.$emit('observable', this);
  },
  methods: {
    async loadContent() {
      this.axiosAbortController = new AbortController();
      this.axiosCancelTokenSource = axios.CancelToken.source();
      let content;
      try {
        this.cancelled = false;
        const customPayload = this.endpoint && this.payload;
        const isPost = this.isUniversal || customPayload;
        let url = this.ssrUrl;
        let payload;
        if (isPost) {
          url = customPayload ? this.customEndpointUrl : this.brandKitSsrUrl;
          payload = customPayload ? this.payload : undefined;
        }
        const requestOptions = {
          method: isPost ? 'post' : 'get',
          url,
          cancelToken: this.axiosCancelTokenSource.token,
          signal: this.axiosAbortController.signal,
          data: payload,
          params: await this.ssrQueryParams(),
        };
        const { data } = await this.$axios.request(requestOptions);
        content = data;
        this.axiosAbortController = null;
        this.$emit('show');
      } catch (e) {
        if (e.message !== 'canceled') throw e;
      }
      return content;
    },
    async setFrameContent() {
      if (!this.ready || !this.ssr || this.isDynamicContent) return;
      try {
        const frame = this.$refs.lazyFrame;
        if (this.clearOnChange) frame.innerHTML = '';
        this.loading = true;
        let content = await this.loadContent();
        if (this.cancelled) {
          this.loading = false;
          this.visible = false;
          this.shown = false;
          this.zIndex = '-999999';
          this.cancelled = false;
          frame.innerHTML = '';
          return;
        }
        if (!content) {
          console.error('No content', this.getId());
          this.ssr = false;
          this.loading = false;
          return;
        }
        content = this.removeAnimations(content);
        this.setContentProperties(content);
        if (!frame) throw new Error('Frame not exists');
        frame.innerHTML = content;
        this.fixHeightIssues(frame);
        this.hiddenCanvasPatch(frame);
        this.fixWheelCanvas(frame);
        this.fixScratchCard(frame);
        if (this.transparentOverlay) this.makeOverlayTransparent(frame);
        this.setVoidLinks(frame);
        this.addAnimationListener(frame);
        this.zIndex = null;
        this.handleSwiperArrows(frame);
      } catch (e) {
        if (e.response?.data.message === 'no_translation') {
          console.log(
            `No translation in ${this.preferredTemplateLanguage} language for template ${this.template.name} (${this.template._id})`,
          );
          // uncomment this if want to hide the template instead, (and move this.ssr = false to 'else' section)
          // but it is not working on main page of chooser for some reason, only in specific theme pages like Essential etc, so need fixing
          // this.$emit('hide');
        } else {
          console.error('Cannot load inline thumbnail', this.getId());
          console.error(e);
        }
        this.ssr = false;
      }
      this.loading = false;
    },
    handleSwiperArrows(iframe) {
      const mql = window.matchMedia('(min-width: 576px)');

      // handle desktop product preview
      if (mql.matches) {
        const swiperButtons = iframe.querySelectorAll("[class*='om-swiper-button']");
        const productsLength = Array.from(iframe.querySelectorAll('.om-product-holder')).length;
        if (productsLength <= 3) {
          Array.from(swiperButtons).forEach((btn) => {
            btn.style.display = 'none';
          });
        }
      }
    },
    onIntersecting() {
      this.visible = true;
      if (this.shown) return;
      this.shown = true;
      this.setFrameContent().then(() => {
        this.$nextTick(() => {
          this.$nextTick(() => {
            this.$emit('contentLoaded');
          });
        });
      });
    },
    onNotIntersecting() {
      const frame = this.$refs.lazyFrame;
      if (this.removeHidden && frame) {
        this.shown = false;
        frame.innerHTML = '';
      }
      if (this.axiosAbortController || this.axiosCancelTokenSource) this.cancelled = true;
      if (this.axiosAbortController) this.axiosAbortController.abort();
      if (this.axiosCancelTokenSource) this.axiosCancelTokenSource.cancel('canceled');
      if (this.shown) {
        this.visible = false;
      }
    },
    fixHeightIssues(frame) {
      const holders = frame.querySelectorAll(
        '.om-holder > .om-container, .om-middle, .om-iframe-container, .om-workspace-content',
      );
      Array.from(holders).forEach((holder) => {
        holder.style.height = '100%';
      });
    },
    fixWheelCanvas(frame) {
      if (!this.hasWheel) return;
      const outerCanvas = frame.querySelector('.om-outer-canvas');
      outerCanvas.style.height = '100%';
      const wheelCanvas = frame.querySelector('.om-wheel-canvas');
      wheelCanvas.style.setProperty('width', '62em', 'important');
    },
    hiddenCanvasPatch(frame) {
      const firstPageHidden = frame.querySelector(
        '.om-outer-canvas > .om-canvas.om-canvas-hidden:first-child',
      );
      if (firstPageHidden) firstPageHidden.classList.remove('om-canvas-hidden');
      const overlay = frame.querySelector('.om-overlay');
      if (overlay) overlay.style.display = null;
    },
    hasCustomOverlayBackground(overlay) {
      const { background } = getComputedStyle(overlay);
      return /.*(gradient|url).*/.test(background);
    },
    makeOverlayTransparent(frame) {
      const overlay = frame.querySelector('.om-overlay');
      if (this.hasCustomOverlayBackground(overlay)) return;
      if (this.hasWheel || (!this.isInterstitial && !this.isFullscreen)) {
        overlay.style.background = 'transparent';
      }
    },
    setVoidLinks(frame) {
      const links = frame.querySelectorAll('a');
      Array.from(links).forEach((link) => {
        link.onclick = function (e) {
          e.preventDefault();
        };
      });
    },
    addAnimationListener(frame) {
      const animatedOverlay = frame.querySelector('.om-overlay-center.om-animated');
      if (animatedOverlay) {
        const handler = function () {
          animatedOverlay.classList.remove('om-animated');
          animatedOverlay.removeEventListener('animationend', handler);
        };
        animatedOverlay.addEventListener('animationend', handler);
      }
    },
    getId() {
      throw new Error('Implement method');
    },
    setContentProperties(content) {
      this.contentProperties.isSidebar = /om-overlay om-sidebar/.test(content);
      this.contentProperties.hasWheel = /om-wheel-canvas/.test(content);
      this.contentProperties.isNanobar = /om-overlay nanobar/.test(content);
      this.contentProperties.fitToScreen = /om-overlay-fit-to-screen/.test(content);
      this.contentProperties.isInterstitial = /om-overlay interstitial/.test(content);
      this.contentProperties.isFullscreen = /om-overlay om-fullscreen/.test(content);
      this.contentProperties.isEmbedded = /om-overlay om-embedded/.test(content);
    },
    fixScratchCard(frame) {
      const scratchCardElement = frame.querySelector('.om-scratch-card.inactive');
      if (scratchCardElement) {
        scratchCardElement.parentElement.style.height = '100%';
        scratchCardElement.style.height = null;
      }
    },
    removeAnimations(content) {
      return content.replace(/"om-overlay-center om-.*?"/g, '"om-overlay-center"');
    },
  },
};
