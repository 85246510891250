const toHexString = (hash) => {
  const hashArray = Array.from(new Uint8Array(hash));
  const hexArray = hashArray.map((b) => b.toString(16).padStart(2, '0'));
  return hexArray.join('');
};

const digestMessage = async (text, algorithm = 'SHA-1') => {
  const buffer = new TextEncoder().encode(text);
  const hash = await window.crypto.subtle.digest(algorithm, buffer);
  return toHexString(hash);
};

const digest = async (data, algorithm) => {
  const asString = JSON.stringify(data);
  if ('crypto' in window && 'TextEncoder' in window && window.crypto.subtle) {
    const hexString = await digestMessage(asString, algorithm);
    return hexString;
  }
  return btoa(asString);
};

export { digest };
